import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {catchError, debounceTime, distinctUntilChanged, map, Observable, switchMap, take, timer} from "rxjs";
import {environment} from "../../../environments/environment";
import {ValidationErrors} from "@angular/forms";

interface getAccountDetails{
  companyId: number;
}

interface getPropertyList{
  skip?: number;
  limit?: number;
  companyId: number;
  order?: {columnName: string; direction:boolean}[]
}

interface submitAccountEdit{
  firstName?: string;
  lastName?: string;
  companyId?: number;
  contactNumber?: string;
  companyName?: string;
}

interface submitPropertyAdd{
  id? :number,
  name: string;
  companyId: number;
  userAccess: any[];
}

interface submitDeleteProperty{
  propertyId: number,
  companyId: number
}

interface getUsersList {
  skip?: number;
  limit?: number;
  status: boolean;
  companyId?: number;
  order?: {columnName: string; direction:boolean}[],
  propertyId: number
}

interface checkProperty{
  propertyId: number;
  name: string;
  companyId?: number;
}

interface checkAddressLabel {
  id: number;
  addressLabel: string
}

interface getAddressList{
  skip?: number;
  limit?: number;
  order: {columnName: string; direction:boolean}[]
}

interface submitInviteUser{
  email: string;
  companyId: number;
  companyName: string;
  roleType: string;
  propertyAccess: {propertyId: number; propertyName: string}[]
}

interface submitDeleteAddress{
  id: number,
}

interface inviteUser{
  email: string;
  companyId: number
}

interface submitUserDelete {
  id: number;
  companyId: number;
  propertyId: number;
}

@Injectable({
  providedIn: 'root'
})
export class ManagementService {

  constructor(
    public http: HttpClient
  ) { }

  /**
   * [GET] API to fetch details of account
   * @param body  Request Body
   */
  getAccountDetails(body : getAccountDetails) : Observable<any> {
    return this.http.get(`${environment.apiUrl}/v2/company/detail?companyId=${body.companyId}`)
      .pipe(take(1), map((response : any) => response.data), catchError((err) => {throw err}))
  }

  /**
   * [POST] API to get list of properties related to account
   * @param body Request Body
   */
  getPropertyList(body : getPropertyList) : Observable<any> {
    return this.http.post(`${environment.apiUrl}/v2/manage/property/list`, body)
      .pipe(take(1), map((response : any) => response.data), catchError((err) => {throw err}))
  }

  /**
   * [PUT] API to update the account details
   * @param body Request body
   */
  submitAccountEdit(body : submitAccountEdit) : Observable<any> {
    return this.http.put(`${environment.apiUrl}/v2/company/update`, body)
      .pipe(take(1), map((response : any) => response.data), catchError((err) => {throw err}))
  }

  /**
   * [POST] API to add new property
   * @param body Request body
   */
  submitPropertyAdd(body : submitPropertyAdd) : Observable<any> {
    return this.http.post(`${environment.apiUrl}/v2/property/add`, body)
      .pipe(take(1), map((response : any) => response.data), catchError((err) => {throw err}))
  }

  /**
   * [PUT] API To update information for a property
   * @param body Request body
   */
  submitPropertyEdit(body : submitPropertyAdd) : Observable<any> {
    return this.http.put(`${environment.apiUrl}/v2/manage/property/update`, body)
      .pipe(take(1), map((response : any) => response.data), catchError((err) => {throw err}))
  }

  /**
   * [DELETE] API to delete a property
   * @param body Request body
   */
  submitDeleteProperty(body: submitDeleteProperty) : Observable<any> {
    return this.http.delete(`${environment.apiUrl}/v2/manage/property/delete?propertyId=${body.propertyId}&companyId=${body.companyId}`)
      .pipe(take(1), map((response : any) => response.data), catchError((err) => {throw err}))
  }

  /**
   * [POST] API to get list of users
   * @param body Request Body
   */
  getUsersList(body: getUsersList) : Observable<any> {
    return this.http.post(`${environment.apiUrl}/account/list`, body)
      .pipe(take(1), map((response : any) => response.data), catchError((err) => {throw err}))
  }

  /**
   * [POST] API to check whether given property name exists already or not
   * @param body Request body
   */
  findWhetherSamePropertyNameExists(body : checkProperty){
    return this.http.post(`${environment.apiUrl}/v2/manage/property/check`, body)
  }

  /**
   * Async validator to check whether property name exists or not (500 ms delay)
   * @param propertyId PropertyId for request body (0 for add)
   * @param companyId Company ID for request body
   */
  samePropertyExistsAsyncValidator(propertyId: number, companyId: number){
    return (formControl: any) : Observable<ValidationErrors | null> => {
      const requestBody = {
        propertyId: propertyId,
        companyId: companyId,
        name: formControl!.value?.trim()
      }

      return timer(500)
        .pipe(switchMap(() => {
          return this.findWhetherSamePropertyNameExists(requestBody)
            .pipe(debounceTime(500), distinctUntilChanged(), map((response : any) => {
              const data = response.data;
              if(data.isNameAvailable){
                return null;
                // Name available, no error
              } else {
                // Name not available
                return {
                  sameValue: true
                }
              }
            }), catchError(err => {throw err}))
        }))
    }
  }

  /**
   * [POST] API to fetch address book
   * @param body Request body
   */
  getAddressList(body : getAddressList){
    return this.http.post(`${environment.apiUrl}/address/list`, body)
      .pipe(take(1), map((response : any) => response.data), catchError((err) => {throw err}))
  }

  /**
   * [POST] API to add a new address to the address book
   * @param body Request body
   */
  submitAddressAdd(body: any){
    return this.http.post(`${environment.apiUrl}/address/add`, body)
      .pipe(take(1), map((response : any) => response.data), catchError((err) => {throw err}))
  }

  /**
   * [DELETE] API to remove an address from the address book
   * @param body Request body
   */
  submitDeleteAddress(body: submitDeleteAddress){
    return this.http.delete(`${environment.apiUrl}/address/delete?id=${body.id}`)
      .pipe(take(1), map((response : any) => response.data), catchError((err) => {throw err}))
  }

  /**
   * [PUT] API to edit an address in the address book
   * @param body Request body
   */
  submitAddressEdit(body: any){
    return this.http.put(`${environment.apiUrl}/address/edit`, body)
      .pipe(take(1), map((response : any) => response.data), catchError((err) => {throw err}))
  }

  /**
   * [POST] API to fetch list of pending invites
   * @param body Request body
   */
  getPendingUsersList(body: any){
    return this.http.post(`${environment.apiUrl}/v2/invite/list`, body)
      .pipe(take(1), map((response : any) => response.data), catchError((err) => {throw err}))
  }

  /**
   * [POST] API to invite a new user
   * @param body Request body
   */
  submitInvitedUser(body: submitInviteUser){
    return this.http.post(`${environment.apiUrl}/v2/invite/user`, body)
      .pipe(take(1), map((response : any) => response.data), catchError((err) => {throw err}))
  }

  /**
   * [POST] API to resend invite to a user
   * @param body Request body
   */
  submitResendInvite(body: inviteUser){
    return this.http.post(`${environment.apiUrl}/v2/invite/resend`, body)
      .pipe(take(1), map((response : any) => response.data), catchError((err) => {throw err}))
  }

  /**
   * [POST] API to revoke a user invite
   * @param body Request body
   */
  submitDeleteInvite(body: inviteUser){
    return this.http.post(`${environment.apiUrl}/v2/invite/delete`, body)
      .pipe(take(1), map((response : any) => response.data), catchError((err) => {throw err}))
  }

  /**
   * [PUT] API to update a user
   * @param body Request body
   */
  submitUserUpdate(body: any){
    return this.http.put(`${environment.apiUrl}/account/type/update`, body)
      .pipe(take(1), map((response : any) => response.data), catchError((err) => {throw err}))
  }

  /**
   * [DELETE] API to remove a user
   * @param body Request body
   */
  submitDeleteUser(body: submitUserDelete){
    return this.http.delete(`${environment.apiUrl}/account/delete?id=${body.id}&propertyId=${body.propertyId}&companyId=${body.companyId}`)
      .pipe(take(1), map((response : any) => response.data), catchError((err) => {throw err}))
  }

}
