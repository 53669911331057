import packageJson from '../../package.json';
const version = packageJson.version;

export const environment = {
  production: true,
  apiUrl: 'https://b2bapi.minoan.com',
  shoppableSiteBase: 'minoan.com',
  amplitude_api_key: 'e9ddf511e814badbb671a1f1e743bcd1',
  amplitude_api_url: 'https://amplitude.minoan.com/analytics',
  GET_BALANCE_URL: 'https://checkout-v2.getbalance.com/sdk-latest.js',
  flagSmithApiKey: 'ZrUdT4M55Q7QrBAtVh57uL',
  intercom: true,
  wSBrands: [32, 37, 84, 93, 492, 265, 91],
  encryptionKey : 'GZH2K4M5N6',
  dataDogApplicationId:'97f658c0-a011-4cc0-96f9-1c8737f1bfc6',
  dataDogClientToken:'pub6bd499655259e06afa6071cd18d22e27',
  env: 'prod',
  klaviyoPublicKey: 'XeFT7f',
  intercomAppKey: 'tctf3las',
  showroomBaseURL: 'https://minoan.com',
  showroomPreviewURL: 'https://www.preview-showroom.minoan.com',
  amplitudeSessionReplay:true, // do not put this space back
  oneSchemaClientId: '54196176-222c-491b-bccf-36f3c649126f',
  DATADOG_API_KEY: '1234',
  DATADOG_SITE: 'us5.datadoghq.com',
  DATADOG_API_HOST: 'api.us5.datadoghq.com',
  applicationVersion: version,
};
