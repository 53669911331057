<ngb-toast
  *ngFor="let message of toastService.toasts"
  [class]="message.styleClass"
  [autohide]="true"
  [delay]="message.life || 5000"
  (hidden)="deleteToast(message)"
>
  <div class="toast_content_wrapper" *ngIf="!message.data && message.severity == 'success'">
    <i class="pi-check"></i>
    <p class="p-toast-detail">{{message.detail}}</p>
  </div>

  <div class="toast_content_wrapper" *ngIf="!message.data && message.severity == 'error'">
    <i class="pi-times-circle"></i>
    <p class="p-toast-detail">{{message.detail}}</p>
  </div>

  <!--    Move to toast-->
  <div class="toast_content_wrapper" style="flex: 1" *ngIf="message.data">
    <ng-container *ngIf="message.data.requestType == 'move'">
      <i class="pi-check"></i>
      <p class="p-toast-detail" *ngIf="message.data.itemListLength == 1">
        Item moved to
        <a routerLink="{{message.data.href}}">
          <ng-container *ngIf="message.data.moveToLocation != 'Quote'">{{message.data.moveToLocation}}</ng-container>
          <ng-container *ngIf="message.data.moveToLocation == 'Quote'">{{message.data.quoteName}}</ng-container>
        </a>.
      </p>
      <p class="p-toast-detail" *ngIf="message.data.itemListLength > 1">{{message.data.itemListLength}} items moved to
        <a routerLink="{{message.data.href}}">
          <ng-container *ngIf="message.data.moveToLocation != 'Quote'">{{message.data.moveToLocation}}</ng-container>
          <ng-container *ngIf="message.data.moveToLocation == 'Quote'">{{message.data.quoteName}}</ng-container>
        </a>.
      </p>
    </ng-container>



    <ng-container *ngIf="message.data.requestType == 'add'">
      <i class="pi-check"></i>
      <p class="p-toast-detail" *ngIf="message.data.itemListLength == 1">
        Item added to
        <a routerLink="{{message.data.href}}">
          <ng-container *ngIf="message.data.moveToLocation != 'Quote'">{{message.data.moveToLocation}}</ng-container>
          <ng-container *ngIf="message.data.moveToLocation == 'Quote'">{{message.data.quoteName}}</ng-container>
        </a>.
      </p>
      <p class="p-toast-detail" *ngIf="message.data.itemListLength > 1">{{message.data.itemListLength}} items added to
        <a routerLink="{{message.data.href}}">
          <ng-container *ngIf="message.data.moveToLocation != 'Quote'">{{message.data.moveToLocation}}</ng-container>
          <ng-container *ngIf="message.data.moveToLocation == 'Quote'">{{message.data.quoteName}}</ng-container>
        </a>.
      </p>
    </ng-container>


  </div>


</ngb-toast>
