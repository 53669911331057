import {Component, HostListener, OnInit} from '@angular/core';
import {AuthService} from "./services/auth/auth.service";
import {NavigationEnd, Router} from "@angular/router";
import {RandomService} from "./services/misc/random.service";
import {scrollToTop} from "./utils/common-functions";
import {IntercomService} from "./services/misc/intercom.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public isHeaderVisible: boolean = false;
  pageType: 'all' | 'checkout' = 'all'
  showFTUXMobile: boolean = true;
  showFTUXDesktop: boolean = true;

  // To check weather current page is product carousel page
  isProductCarousel: boolean = false;

  constructor(
    public auth: AuthService,
    public router: Router,
    private randomService: RandomService,
    public intercom: IntercomService,
  ) {

  }

  ngOnInit() {

    this.detectOS();
    // document.body.classList.add("os");

    this.router.events.subscribe({
      next: events => {
        if (events instanceof NavigationEnd) {
          this.isProductCarousel = events.urlAfterRedirects.includes('/shop-products');
          this.showFTUXMobile = (events.urlAfterRedirects.includes('/shop') && !events.urlAfterRedirects.includes('/shop-products')) || events.urlAfterRedirects.includes('/brands')
          this.showFTUXDesktop = !(events.urlAfterRedirects.includes('/onboarding/chromedownload') || events.urlAfterRedirects.includes('/onboarding/chromedownload/celebration'))
          if (events.urlAfterRedirects.includes('auth/login') || events.urlAfterRedirects.includes('setup-account') || events.urlAfterRedirects.includes('expired-link') || events.urlAfterRedirects.includes('reset-password')  || events.urlAfterRedirects.includes('reset-ui') || events.urlAfterRedirects.includes('forgot-password') || events.urlAfterRedirects.includes('create-password') || events.urlAfterRedirects.includes('showroom/setup')  || events.urlAfterRedirects.includes('/download')){
            this.isHeaderVisible = false;
          } else {
            if (events.urlAfterRedirects.includes('onboarding')){
              this.isHeaderVisible = events.urlAfterRedirects.includes('onboarding/chromedownload');
            } else {
              this.isHeaderVisible = true;
            }

            // To change the type of header
            if(events.urlAfterRedirects.includes('/checkout')) {
              this.pageType = 'checkout';
            } else {
              this.pageType = 'all';
            }
          }

          if(events.urlAfterRedirects.includes('/orders/detail') || events.urlAfterRedirects.includes('/quotes/detail')) {
            const parsedUrl = this.router.parseUrl(events.urlAfterRedirects);
            const segments = parsedUrl.root.children['primary'].segments
            const lastSegment = parsedUrl.root.children['primary'].segments[segments.length - 1]
            this.intercom.updateIntercomOrderId(lastSegment.path)
          } else {
            this.intercom.updateIntercomOrderId(null)
          }

          // Pages where you are not supposed to scroll to top
          // ? means Query params are involved, so we may be sorting/filtering
          if(!(events.urlAfterRedirects.includes('/management/users?')) && this.randomService.SHOULD_SCROLL.getValue()){
            scrollToTop();
          }

          // To clear propertyId from session storage
          if(!events.urlAfterRedirects.includes('/showroom')) {
            const redirectUrl = sessionStorage.getItem('redirectUrl');
            if(!redirectUrl) {
              sessionStorage.removeItem('propertyId');
            }
            sessionStorage.removeItem('selfServeState');
            document.body.classList.add('non-showroom')
          } else {
            document.body.classList.remove('non-showroom')
            // this.auth.updateShowroomBadge();
          }

          // To add class to body tag when setting up a showroom
          if(!events.urlAfterRedirects.includes('/showroom/setup')) {
            document.body.classList.add('non-scrollbar')
          } else {
            document.body.classList.remove('non-scrollbar')
          }

        }
      }
    });
  }
  prevPos = 0;

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    this.randomService.isScrollingTopUpdated = false;
    let currentPos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
    if((currentPos - this.prevPos) < 0 ){
      this.prevPos = currentPos + 1;
      // console.log('scrolling top');
      this.randomService.isScrollingTopUpdated = true;
      this.randomService.isScrollingTop.next(true);
    }
    else{
      // console.log('scrolling bottom');
      this.prevPos = currentPos;
      this.randomService.isScrollingTopUpdated = true;
      this.randomService.isScrollingTop.next(false);
    }
  }

  detectOS() {
    const userAgent = navigator.userAgent;
    if(userAgent.includes('Windows')){
      document.body.classList.add('windows')
    }
    const isMac = navigator.platform.indexOf('Mac') > -1;
    if (isMac) {
      document.body.classList.add('mac');
    }
    if (/iPhone|iPad|iPod/.test(navigator.userAgent) && /CriOS/.test(navigator.userAgent)) {
      document.body.classList.add('for-chrome')
    }
  }



}

