import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {PLACEHOLDER_URL, SELF_SERVE_PLACEHOLDER_URL} from "../../../constants/constants";
import {ErrorsService} from "../../../services/customer-onboarding/errors.service";


@Component({
  selector: 'product-image-carousel',
  templateUrl: './product-image-carousel.component.html',
  styleUrls: ['./product-image-carousel.component.scss']
})
export class ProductImageCarouselComponent implements OnChanges {

  mobileCarousel: boolean = false
  imagesWrongChecked: boolean = false;

  /**
   * To show images loading skeleton
   */
  @Input() showSkeleton: boolean = false;

  /**
   * To show skeleton on main image
   */
  @Input() showMainImageSkeleton: boolean = false;

  @Input() type: 'quote' | 'self_serve' = 'quote'

  protected  PLACEHOLDER_URL = PLACEHOLDER_URL;


  constructor(public errorService: ErrorsService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['images']) {
      const currentValue = changes['images'].currentValue
      this.mainImageIndex = 0;
      this.mobileCarousel = false;
      this.imagesWrongChecked = false;
      if(currentValue.length > 1) {
        this.scrollImageCarousel();
      }

      this.imagesWrongChecked = this.noImageSelected;
      if(this.imagesWrongChecked) {
        this.mainImageChanged.emit({imgUrl: '', imgNumber: -1})
      } else {
        this.fireImageChangedEvent()
      }
    }

    if(changes['type']){
      this.PLACEHOLDER_URL = this.type === 'quote' ? PLACEHOLDER_URL : SELF_SERVE_PLACEHOLDER_URL;
    }
  }

  @Input() images: string[] = []

  // For image carousel
  @Input() carouselOptions: {imageCount: number} = {
    imageCount: 6
  }

  @Output() mainImageChanged = new EventEmitter<{ imgUrl: string, imgNumber: number }>()

  mainImageIndex: number = 0;

  @Input() noImageSelected: boolean = false;

  @Input() isNoImage: boolean = false;

  @Output() imageUploaded = new EventEmitter<any>

  /**
   * Select next image from image carousel
   */
  nextImage() {
    this.mainImageIndex = this.mainImageIndex + 1 >= this.images.length ? 0 : this.mainImageIndex + 1
    this.fireImageChangedEvent()
    this.scrollImageCarousel();
  }

  /**
   * Select previous image from image carousel
   */
  prevImage() {
    this.mainImageIndex = this.mainImageIndex == 0 ? (this.images.length - 1) : (this.mainImageIndex - 1);
    this.fireImageChangedEvent();
    this.scrollImageCarousel();
  }

  /**
   * Fire event for image changed in carousel
   */
  fireImageChangedEvent() {
    const selectedImage = this.images[this.mainImageIndex];
    this.mainImageChanged.emit(selectedImage == PLACEHOLDER_URL ? {imgUrl: '', imgNumber: -1} : {imgUrl: selectedImage, imgNumber: this.mainImageIndex + 1})
  }

  /**
   * Change image to clicked image
   * @param index Image index
   */
  imageClicked(index: number) {
    if(!this.imagesWrongChecked) {
      this.mainImageIndex = index
      this.fireImageChangedEvent()
    }
  }

  /**
   * Scroll currently selected image into view
   */
  scrollImageCarousel(){
    setTimeout(() => {
      try {
        if(this.type !== 'self_serve') {
          document.getElementById(`productImage-${this.mainImageIndex}`)!.scrollIntoView();
        }
      } catch (e) {
        console.log(e)
      }
    }, 300)
  }

  imagesWrongCheckedEventHandler(event: {checked: boolean, originalEvent: PointerEvent}) {
    if(event.checked) {
      this.mainImageChanged.emit({imgUrl: '', imgNumber: -1})
    } else {
      this.fireImageChangedEvent();
    }
  }

  get showNoneOfTheseCheckbox() {
    if(this.type === "self_serve") {
      return false;
    }
    return this.images.length > 1 && this.images[0] !== PLACEHOLDER_URL
  }

  /**
   * emit event when file is uploaded
   * @param event
   */
  fireEventOnImageUpload(event: any) {
    this.imageUploaded.emit(event)
  }
}
