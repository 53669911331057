// THIS FILE IS FOR COMMON FUNCTIONS LIKE COPY/PASTE, SCROLL TO TOP etc.


import {isEqual} from "lodash-es";
import {TEMPORARY_ONBOARDING_SESSION_VARIABLE} from "../constants/constants";

/**
 * Scroll to top of window
 */
const scrollToTop = (): void => {
  setTimeout(function() {
    window.scroll(0,0)
  },100);

}

/**
 * Compares whether 2 arrays are equal or not
 * @param arr1 Array 1
 * @param arr2 Array 2
 * @param [checkIndex=false] false = Check if 2 arrays have same values regardless of index, true = Check whether 2 arrays are exactly same i.e. all values are same indices
 */
const compare2Arrays = (arr1: Array<any>, arr2: Array<any>, checkIndex: boolean = false): boolean => {
  if(arr1.length != arr2.length){
    return false;
  } else {
    if(!checkIndex){
      return arr1.every((everyValueInArr1: any) => arr2.indexOf(everyValueInArr1) != -1)
    } else {
      return arr1.every((everyValueInArr1: any, index: number) => arr2[index] == everyValueInArr1)
    }
  }
}

/**
 * Compares whether 2 arrays are equal or not
 * @param arr1 Array 1
 * @param arr2 Array 2
 * @param [checkIndex=false] false = Check if 2 arrays have same values regardless of index, true = Check whether 2 arrays are exactly same i.e. all values are same indices
 * @return Returns false if 2 arrays are not equal else true
 */
const compare2ArraysObjects = (arr1: Array<any>, arr2: Array<any>, checkIndex: boolean = false): boolean => {
  if(arr1.length != arr2.length){
    return false;
  } else {
    if(!checkIndex){
      return arr1.every((everyValueInArr1: any) => arr2.findIndex((each: any) => isEqual(each, everyValueInArr1)) != -1)
    } else {
      return arr1.every((everyValueInArr1: any, index: number) => isEqual(arr2[index], everyValueInArr1))
    }
  }
}

/**
 * Takes a link and converts it into http or https link (if not present)
 * @param link Link to convert
 * @returns {String} Converted link
 */
const returnLinkAsHttp = (link: string): string => {
  if(link.includes('http') || link.includes('https')){
    return link;
  } else {
    return `http://${link}`
  }
}

/**
 * Returns true if userAgent is mobile
 */
const onlyOnMobile = (): boolean =>  {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
}

/**
 * Returns true if userAgent is desktop
 */
const onlyOnDesktop = (): boolean =>  {
  return !/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
}

/**
 * Create request body for quote
 * @param requestType Type of request
 * @param itemListLength Length of item list
 * @param href Router link for anchor tag
 * @param moveToLocation Where product/products are moved to
 * @param quoteName Name of quote if moveToLocation = Quote
 */
const createToastBody = (requestType: string, itemListLength: number, href: string, moveToLocation: string, quoteName: string | undefined = undefined) => {
  return {
    requestType: requestType,
    itemListLength: itemListLength,
    href: href,
    moveToLocation: moveToLocation,
    quoteName: quoteName
  }
}

/**
 * Copies a string to the keyboard
 * @param s String to be copied
 */
const copyStringToClipboard = (s: string): void => {
  try {
    navigator.clipboard.writeText(s).then();
  } catch (e) {
    console.log(e);
  }
}
/**
 * Create visitor URL with validation
 * If pathname does not include shopall, then append shopall to pathname
 * @param weblink Weblink to create visitor URL
 * @param token Token to append to visitor URL
 */
const createVisitorUrlWithValidation = (weblink: string, token: string) => {
  const link = new URL(weblink);
  if(!link.pathname.includes('shopall') && !link.pathname.includes('product-detail')){
    link.pathname = `/single-space/shopall`;
  }
  // Append visitor validation key
  if(token) {
    link.searchParams.append('u', token);
  }
  return link;
}

/**
 * Creates new FTUX Array after an event occurs
 * @param currentFtuxArray Current FTUX Array
 * @param indexToBeChanged Index of executed event
 * @param value Value to be set at current index and all preceding indices
 */
const createFtuxArray = (currentFtuxArray: boolean[], indexToBeChanged: number, value: boolean) => {
  const arr = [...currentFtuxArray]
  for (let i = 1; i <= indexToBeChanged; i++) {
    arr[i] = value;
  }
  return arr;
}

/**
 * Appends _revisit to screen impression on onboarding pages if temporary onboarding is true
 * @param currentScreenName Current Screen Name
 */
const returnOnboardingScreenImpressionName = (currentScreenName: string) => {
  const isTemporaryOnboarding = !!sessionStorage.getItem(TEMPORARY_ONBOARDING_SESSION_VARIABLE)
  return isTemporaryOnboarding ? currentScreenName + '_revisit' : currentScreenName
}

/**
 * Returns a formatted currency
 * @param value {number} Value to be formatted
 * @return {string}
 */
export const currencyFormatter = (value: number) => {
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: value >= 10000 ? 0 : 2
  });
  return USDollar.format(value);
}

/**
 * Returns a formatted number
 * @param value {number} Value to be formatted
 * @return {string}
 */
export const numberFormatter = (value: number) => {
  if(value < 1000) {
    return value;
  }else if (value < 10000) {
    return (value / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
  } else {
    return Math.round(value / 1000) + 'k';
  }
}


export {
  scrollToTop,
  compare2Arrays,
  returnLinkAsHttp,
  onlyOnMobile,
  onlyOnDesktop,
  createToastBody,
  compare2ArraysObjects,
  copyStringToClipboard,
  createVisitorUrlWithValidation,
  createFtuxArray,
  returnOnboardingScreenImpressionName,
}
