// THIS FILE IS FOR CREATING COMMON FORM ELEMENTS LIKE VALIDATORS, GOOGLE AUTOCOMPLETE COMMON FUNCTION etc.


import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";
import {EMAIL_REGEX, STATES} from "../constants/constants";

const formValidators = {
  /**
   * Returns required error if trimmed value of input is empty
   * @param control FormControl
   */
  noWhiteSpace: (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value && control.value.trim().length === 0) {
      return {required: true};
    }
    return null;
  },

  /**
   * Returns emailError if all emails in string are not valid
   * @param control FormControl
   */
  emailStringValidator(control: FormControl) {
    const val = control.value;
    const listOfEmails = val.split(',');
    const isEmailStringInvalid = listOfEmails.some((email: string) => {
      if (!email.match(EMAIL_REGEX)) {
        return true;
      } else {
        return false;
      }
    })

    if (isEmailStringInvalid) {
      return {'emailError': true};
    } else {
      return null;
    }
  },

  /**
   * Returns minlength error if array length is 0
   * @param control FormControl
   */
  arrayLength0Validator: (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value && control.value.length == 0) {
      return {
        minlength: true
      }
    }
    return null;
  },

  /**
   * Returns whether a control value is repeated above or not
   * @param controlName Control Value to be checked
   */
  sameValueInFormArray: (controlName: string) => {
    return (formArray : any) : ValidationErrors | null => {
      const length = formArray.controls.length;
      for (let i = length - 1; i > 0; i--){
        const currentValue = formArray.getRawValue()[i]
        const value = formArray.getRawValue().slice(0, i);
        if(currentValue[controlName] !== ''){
          if(value.find((eachProperty : any) => eachProperty[controlName] == currentValue[controlName])){
            returnAsFormGroup(formArray.controls[i])!.get(controlName)!.setErrors({
              sameValue: true
            })
          }
        }
      }
      return null;
    }
  },

  /**
   * Returns whether value in controlName is not equal to the specified value
   * @param controlName control name to check
   * @param value value control should not be equal to
   */
  notEqualToSpecificValue : (controlName: string, value: string) => {
    return (formGroup : any) : ValidationErrors | null => {
      const formControl = formGroup.get(controlName)!
      if(formControl.value! == value){
        formControl.setErrors({
          sameValue: true
        })
      }
      return null;
    }
  },

  /**
   * Check whether entered state is valid or not
   * @param control Form Control
   */
  validStateValidator: (control: AbstractControl): { [key: string]: boolean } | null => {
    const controlValue = control.value?.trim();
    const stateAbbreviationMap = STATES.map(eachState => eachState.abbreviation);

    if(control.value == ''){
      return null;
    } else {
      if(stateAbbreviationMap.indexOf(controlValue) == -1){
        return {
          maxlength: true
        }
      }
      return null;
    }


  },

  /**
   * Check whether both words entered have same value or not
   * @param passwordControl1 Password control 1
   * @param passwordControl2 Password control 2
   */
  samePasswordsEntered: (passwordControl1: string, passwordControl2: string) => {
    return (formGroup : any) : ValidationErrors | null => {
      const password1 = formGroup.get(passwordControl1)!
      const password2 = formGroup.get(passwordControl2)!
      if(password1.value! !== password2.value!){
        return {
          passwordMismatch: true
        }
      }
      return null;
    }
  },

  /**
   * Check whether form control value exists in given list or not
   * @param comparisonList Comparison list
   */
  sameValueInList: (comparisonList: string[]) => {
    return (formControl : any) : ValidationErrors | null => {
      const value = formControl.value.trim()!
      if(value) {
        if(comparisonList.map(each => each.toLowerCase()).indexOf(value.toLowerCase()) != -1){
          return {
            sameValue: true
          }
        }
      }
      return null;
    }
  },

  specialCharacterNotAllowed: (control: any): any => {
    const nameRegexp: RegExp = /[;/?:@<>#%{}|\\^~\[\]]/; //  /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    if (control.value && nameRegexp.test(control.value)) {
      return { invalidName: true };
    }
    return null;
  },

  allCapitalLetters: (control: any): any => {
    const capsRegex: RegExp = /^[A-Z\s]+$/;
    if (control.value && control.value.length >=3 && capsRegex.test(control.value) ) {
      return { allCapitals: true };
    }
    return null;
  },

  emojisNotAllowed: (control: any): any => {
    const emojiRegex: RegExp = /(?!(?:[\u2019, \u2032, \u2018, \u201C, \u201D]))(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;
    if (control.value && emojiRegex.test(control.value) ) {
      return { hasEmoji: true };
    }
    return null;
  },

  /**
   * Custom Validator for Upload File extensions
   * @param allowedExtensions any
   */
  fileUploadValidator: (allowedExtensions: any): ValidatorFn  => {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== undefined && isNaN(control.value)) {
        const file = control.value;
        const ext = file.substring(file.lastIndexOf('.') + 1);
        if (!allowedExtensions.includes(ext.toLowerCase())) {
          return { invalidType: true };
        }
      }
      return null;
    };
  },

  /**
   * Custom Validator for Upload File extensions using drag and drop
   * @param allowedExtensions
   * @param fileName
   */
  fileUploadValidatorDragNDrop: (fileName: string, allowedExtensions: any): boolean  => {
    const ext = fileName.substring(fileName.lastIndexOf('.') + 1);
    return !allowedExtensions.includes(ext.toLowerCase());
  },

  /**
   * Custom Validator to validate whether at least one control has length greater than 0
   */
  someControlLengthGreaterThan0Validator: (control = '') => {
    return (formGroup: any): ValidationErrors | null => {
	    const formControls = formGroup.controls;
      const isValid = Object.keys(formControls).some(controlName => {
	      const control = formControls[controlName];
	      return control.value && control.value.length > 0;
      });
      return isValid ? null : {allControlsLength: true};
    }
  },

  /**
   * Custom Validator to check length of all controls is less than supplied max length
   * @param maxLength Max length
   */
  allControlsMaxLengthValidator: (maxLength: number) => {
    return (formGroup: any): ValidationErrors | null => {
      const formControls = formGroup.controls;
      const totalLength = Object.keys(formControls).reduce((acc, controlName) => {
        const control = formControls[controlName];
        acc = acc + (control.value ? control.value.length : 0)
        return acc;
      }, 0);
      const isValid = totalLength <= maxLength;
      return isValid ? null : {maxlength: true};
    }
  },

  /**
   * Check whether form control value exists in given list or not
   * @param comparisonList Comparison list
   */
  valueNotInList: (comparisonList: string[]) => {
    return (formControl : any) : ValidationErrors | null => {
      const value: string[] | null = formControl.value!
      if(value) {
        const valueMap = value!.reduce((acc: { [p:string]: any }, each) => {
          acc[each.toLowerCase()] = each;
          return acc
        }, {});

        if(value.length) {
          // check if any value is in comparison list
          const valueExists = comparisonList.map(each => each.toLowerCase()).some(each => !!valueMap[each])
          return valueExists ? {
            sameValue: true
          } : null

        }
      }
      return null;
    }
  },
}

/**
 * Return Abstract Control as FormGroup
 * @param control Abstract Control
 */
const returnAsFormGroup = (control: any): FormGroup => {
  return control as FormGroup;
}

/**
 * Return Abstract Control as FormControl
 * @param control Abstract Control
 */
const returnAsFormControl = (control: AbstractControl): FormControl => {
  return control as FormControl;
}

/**
 * Convert phone number from p-inputMask format {(201)-201-2012} to API format {+1 2012012012}
 * @param phone Phone Number
 */
const phoneToApiFormat = (phone: string): string => {
  if(phone){
    if(phone == '-'){
      return '';
    } else if(phone == ''){
      return '';
    } else {
      return '+1 ' + phone.replace(/\D/g, '');
    }
  } else {
    return ''
  }
}

/**
 * Convert API phone number from API format {+1 2012012012} to p-inputMask format {(201)-201-2012}
 * @param phone Phone Number
 */
const phoneToViewFormat = (phone: string): string => {
  if(phone){
    if(phone == '-'){
      return '';
    } else if(phone == ''){
      return '';
    } else {
      let phoneNumber = phone.replace('+1', '').replace(/\D/g, '');
      phoneNumber = `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(3,6)}-${phoneNumber.substring(6, phone.length)}`//+1 9028364756 or +19028364756 ==> 19028364756, remove 1(US CODE)
      return phoneNumber
    }
  } else {
    return ''
  }
}

/**
 * Return filtered object from Google Autocomplete API
 * @param place Google place
 */
const googleAutoComplete = (place: any) : googleAutoCompleteInterface => {
  let line1 = '';
  let obj : googleAutoCompleteInterface = {
    city: '',
    state: '',
    zip: '',
    address1: '',
    state_code: '',
    latitude: 0,
    longitude: 0
  }
  obj.latitude = place.geometry?.location?.lat()!
  obj.longitude = place.geometry?.location?.lng()!
  place.address_components?.filter((i : any) => {
    i.types?.filter((j : any) => {
          if (j == 'street_number') {
            line1 = line1 + i?.long_name + ' ';
          }
          if (j == 'route') {
            line1 = line1 + i?.long_name;
          }
          if (j == 'locality'){
            obj.city = i?.long_name;
          }

          if(!obj.city){
            if (j == 'sublocality'){
              obj.city = i?.long_name;
            }
          }

          if (j == 'administrative_area_level_1'){
            obj.state_code = i?.short_name
            obj.state = i?.long_name;
          }

          if (j == 'postal_code') {
            obj.zip = i.long_name;
          }
        });
  });
  if (line1 == ''){
    obj.address1 = place?.address_components?.[0]? place?.address_components?.[0]?.long_name : place?.name;
  } else {
    obj.address1 = line1;
  }

  return obj;
}

/**
 * Convert Address to delivery address string (name, address1, address2, city, province zip)
 * @param selectedAddressInfo Selected Address to be converted
 */
const convertToDeliveryAddress = (selectedAddressInfo: any ) => {
  const deliveryAddress = `${selectedAddressInfo!.name}, ${selectedAddressInfo!.address1}${selectedAddressInfo!.address2 == '' ? '' : `, ${selectedAddressInfo!.address2}`}, ${selectedAddressInfo!.city}, ${selectedAddressInfo!.provinceCode} ${selectedAddressInfo!.zip}`
  return deliveryAddress
}

/**
 * Convert Address to address string for list pages (address1, address2, city, province zip)
 * @param selectedAddressInfo Selected Address to be converted
 */
const convertToAddressString = (selectedAddressInfo: any ) => {
  const deliveryAddress = `${selectedAddressInfo!.address1}${selectedAddressInfo!.address2 == '' ? '' : `, ${selectedAddressInfo!.address2}`}, ${selectedAddressInfo!.city}, ${selectedAddressInfo!.provinceCode} ${selectedAddressInfo!.zip}`
  return deliveryAddress
}

/**
 * Convert Date object to string date format (2023-11-31)
 * @param date Date
 */
const convertDateToString = (date: Date) => {
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth()+1}` : `${date.getMonth()+1}`
  const day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
  return `${date.getFullYear()}-${month}-${day}`
}

/**
 * Rounds a number
 * @param number Number
 */
const roundNumber = (number: number) => {
  if (Number.isNaN(number)) {
    return 0;
  } else {
    return Math.round((number + Number.EPSILON) * 100) / 100;
  }
}

interface googleAutoCompleteInterface{
  city: string;
  state: string;
  zip: string;
  address1:string;
  state_code:string;
  latitude: number;
  longitude: number;
}

const generateNewShowroomName =  (name: string) => {
  return name
    .trim()
    .toLowerCase()
    .replace(/[^\w\s\-]/g, '')
    .trim()
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-');
}

export {
  formValidators,
  returnAsFormGroup,
  returnAsFormControl,
  phoneToApiFormat,
  phoneToViewFormat,
  googleAutoComplete,
  convertToDeliveryAddress,
  convertToAddressString,
  convertDateToString,
  roundNumber,
  generateNewShowroomName
}
